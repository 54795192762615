<template>
  <b-container>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.fname") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.fname.$model"
            :placeholder="$t('label.fname')"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.fname.required && $v.form.fname.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.fname.maxLength && $v.form.fname.$dirty"
          >
            {{ $t("info.maxLength", [75]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.lname") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.lname.$model"
            :placeholder="$t('label.lname')"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.lname.required && $v.form.lname.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.lname.maxLength && $v.form.lname.$dirty"
          >
            {{ $t("info.maxLength", [75]) }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.email") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.email.$model"
            :placeholder="$t('label.email')"
            readonly
          ></b-form-input>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.mobile") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.tel.$model"
            placeholder="format: 1111111111"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.tel.maxLength && $v.form.tel.$dirty"
          >
            {{ $t("info.maxLength", [10]) }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.tel.minLength && $v.form.tel.$dirty"
          >
            {{ $t("info.minLength", [10]) }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="4">
          <div class="mt-4">
            <strong>{{ $t("label.officePhone") }}</strong>
          </div>
          <b-form-input
            v-model="form.businessPhones"
            placeholder="format: 1111111111"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="
              !$v.form.businessPhones.maxLength && $v.form.businessPhones.$dirty
            "
          >
            {{ $t("info.maxLength", [10]) }}
          </div>
          <div
            class="text-danger"
            v-if="
              !$v.form.businessPhones.minLength && $v.form.businessPhones.$dirty
            "
          >
            {{ $t("info.minLength", [10]) }}
          </div>
        </b-col>
        <b-col sm="2">
          <div class="mt-4">
            <strong>{{ $t("label.ext") }}</strong>
          </div>
          <b-form-input
            v-model="form.ext"
            placeholder="format: 11111"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.ext.maxLength && $v.form.ext.$dirty"
          >
            {{ $t("info.maxLength", [5]) }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.ext.minLength && $v.form.ext.$dirty"
          >
            {{ $t("info.minLength", [3]) }}
          </div>
        </b-col>
        <b-col sm="6">
          <div class="mt-4">
            <strong>{{ $t("label.communication") }}</strong>
          </div>
          <b-form-select
            v-model.trim="$v.form.communication.$model"
            :options="communication"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="
              !$v.form.communication.required && $v.form.communication.$dirty
            "
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="bypassOffice" sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.offices") }}</strong>
          </div>
          <b-form-input v-model="form.office" readonly></b-form-input>
        </b-col>

        <b-col v-else sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.offices") }}</strong>
          </div>
          <v-select
            v-model.trim="$v.form.office.$model"
            :options="buildings"
            :selectable="(option) => !option.Flag"
            :filter="searchBuilding"
            label="ResName"
            :placeholder="$t('action.chooseBuilding')"
            :value="form.office" 
            @input="setJobTitleList"
          >
            <template slot="option" slot-scope="option">
              <strong
                style="color: black"
                v-if="option.Flag === 'ClassName'"
                disabled
                >{{ option.ResName }}</strong
              >
              <span v-else>{{ option.ResName }}</span>
            </template>
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchBuilding") }}</small>
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.office.required && $v.form.office.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <b-button
            variant="link"
            @click="openmodal('sendNotificationEmail', null)"
            >{{ $t("label.cannotFindMyBuilding") }}</b-button
          >
        </b-col>
        <b-col sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.job") }}</strong>
          </div>
          <v-select
            v-model.trim="$v.form.job.$model"
            :options="jobTitleList"
            :selectable="(option) => !option.Flag"
            :filter="searchJobTitleList"
            label="jobTitle"
            :placeholder="$t('label.job')"
          >
            <template slot="option" slot-scope="option">
              <strong
                style="color: black"
                v-if="option.Flag === 'ClassName'"
                disabled
                >{{ option.jobTitle }}</strong
              >
              <span v-else>{{ option.jobTitle }}</span>
            </template>
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.jobTitleList") }}</small>
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.job.required && $v.form.job.$dirty"
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="bypassManager === 'needValidation'" sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.manager") }}</strong> :
            {{ displayManagerName() }}
          </div>
          <v-select
            v-model.trim="$v.form.manager.$model"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseManager')"
            label="displayName"
          >
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchByName") }}</small>
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.manager.required && $v.form.manager.$dirty"
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
        <b-col v-else sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.manager") }}</strong> :
            {{ displayManagerName() }}
          </div>
          <v-select
            v-model.trim="form.manager"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseManager')"
            label="displayName"
          >
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchByName") }}</small>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="user.directReports.length > 0">
        <b-col>
          <b-form-checkbox
            id="bypassManager"
            v-model="bypassManager"
            name="checkboxBypassManager"
            value="bypass"
            unchecked-value="needValidation"
          >
            {{ $t("info.bypassManager") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-button
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        v-if="tenantAccess"
        >{{ $t("action.submitUserForm") }}</b-button
      >
    </b-form>

    <hr />

    <b-modal
      id="sendNotificationEmail"
      :title="$t('label.emailNotification')"
      hide-footer
    >
      <SendNotificationEmail modalid="sendNotificationEmail" />
    </b-modal>

    <b-modal
      id="deleteUserConfirmation"
      :title="$t('label.removeUser')"
      hide-footer
    >
      <DeleteUserConfirmation
        :userToUpdate="userToUpdate"
        modalid="deleteUserConfirmation"
      />
    </b-modal>

    <b-modal id="addUserToManager" size="lg" :title="$t('label.addUserToManager')" hide-footer>
      <AddUserToManager :manager="userToUpdate" modalid="addUserToManager" />
    </b-modal>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
          <p>{{ $t("info.delayedUpdate") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="info" class="text-center pt-4">
      <b-col>
        <b-alert show variant="info">
          <p>{{ info }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>

    <!-- Organigramme -->
    <b-container>
      <div v-if="managers.length > 0">
        <b-row
          v-for="manager in managers"
          :key="manager.id"
          class="justify-content-center mt-4 relative"
        >
          <b-col>
            <div class="userBox">
              <div class="displayName mt-2">{{ manager.displayName }}</div>
              <div class="jobTitle mb-2">{{ manager.jobTitle }}</div>
            </div>
            <div class="verticalLine justify-content-center"></div>
          </b-col>
        </b-row>
      </div>
      <div v-if="user.directReports.length > 0">
        <b-row class="justify-content-center mt-4 relative">
          <b-col>
            <div class="userBoxActive">
              <div class="displayName mt-2">{{ user.displayName }}</div>
              <div class="jobTitle mb-2">{{ user.jobTitle }}</div>
            </div>
            <div class="verticalLine justify-content-center"></div>
          </b-col>
        </b-row>
      </div>

      <hr v-if="directReports.length > 0" class="mt-4" />

      <b-row class="justify-content-center mt-4">
        <b-col sm="3" v-for="sub in directReports" :key="sub.id" class="mt-4">
          <div :class="sub.id === form.id ? 'userBoxActive' : 'userBox'">
            <div class="displayName mt-2">{{ sub.displayName }}</div>
            <div class="jobTitle mb-2">{{ sub.jobTitle }}</div>
            <b-button
              v-if="user.directReports.length > 0"
              variant="link"
              @click="openmodal('deleteUserConfirmation', sub)"
              >{{ $t("label.removeUser") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4">
          <b-button
            v-if="user.directReports.length > 0"
            variant="link"
            @click="openmodal('addUserToManager', user)"
            >{{ $t("label.addUserToManager") }}</b-button
          >
      </b-row>
    </b-container>
    <!--  -->
  </b-container>
</template>

<script>
import axios from "axios";
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import "vue-search-select/dist/VueSearchSelect.css";
import SendNotificationEmail from "./Modal/SendNotificationEmail";
import DeleteUserConfirmation from "./Modal/DeleteUserModal.vue";
import AddUserToManager from "./Modal/AddUserToManager.vue";
import { globalF } from "./Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      form: {
        id: this.getData("id"),
        fname: this.getData("givenName"),
        lname: this.getData("surname"),
        email: this.getData("mail"),
        tel: this.getPhoneNumber("mobilePhone"),
        job: this.getJobTitle(),
        office: this.getOfficeData(),
        businessPhones: this.getPhoneNumber("businessPhones"),
        communication: this.getData("preferredLanguage"),
        ext: this.getExt(),
        manager: "", 
        IdManager: "",
      },
      allDomains: [],
      managers: [],
      directReports: this.setUserDirectReports(),
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      buildings: [],
      jobTitleList: [],
      error: "",
      info: "",
      bypassManager: "needValidation",
      userToUpdate: null,
    };
  },
  validations() {
    let form = {
      fname: {
        required,
        maxLength: maxLength(75),
      },
      lname: {
        required,
        maxLength: maxLength(75),
      },
      email: {
        required,
        email,
        maxLength: maxLength(75),
      },
      tel: {
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      businessPhones: {
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      ext: {
        maxLength: maxLength(5),
        minLength: minLength(3),
      },
      communication: {
        required,
      },
      job: {
        required
      },
    };

    if (this.bypassManager === "needValidation") {
      form.manager = { required };
    }

    if (!this.bypassOffice) {
      form.office = { required };
    }

    return { form };
  },
  mounted() {
    
    this.spinner = true;
    let userToken = this.$store.getters.getUserToken;
    if (userToken) {
      let request = [
        this.getManager(userToken),
        this.getManagerChains(userToken),
      ];
      Promise.allSettled(request).then((results) => {
        this.spinner = false;
        if (results.length === 2) {
          let user = this.$store.getters.getUser;
          let manager = results[0];
          let managerRoot = results[1];

          if (manager.value) {
            if (manager.value.status === 200) {
              this.form.manager = manager.value.data;
              if (this.form.manager) {
                this.form.IdManager = manager.value.data.id;
                //If the user is not a manager, get direct report from the manager
                if (this.form.IdManager && user.directReports.length === 0) {
                  //Get people under the manager
                  this.getUserDirectReports(userToken, this.form.IdManager);
                }
              }
            }
          }

          if (managerRoot.value) {
            if (managerRoot.value.status === 200) {
              this.getManagerRoot(managerRoot.value.data);
            }
          }

          this.getBuildingList();
          let office = this.getOfficeData();
          this.setJobTitleList(office);
          this.getAllDomains(userToken);
        } else {
          this.error = this.$t("info.errorgeneric");
        }
      });
    } else {
      this.error = this.$t("info.errorgeneric");
    }
  },
  computed: {
    currentLg: function () {
      return this.$i18n.locale;
    },
    tenantAccess: function () {
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let currentMicrosoftAccount = this.$store.getters.getMicrosoftAccount;
      let accessList = tenantAccess["userForm"];
      
      return accessList.includes(currentMicrosoftAccount.tenantId);
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    bypassOffice: function () {
      return this.$store.getters.getEmailNoBuildingSent;
    },
    communication: function () {
      return [
        { value: null, text: this.$t("label.language") },
        { value: "fr-FR", text: this.$t("label.french") },
        { value: "en-US", text: this.$t("label.english") },
      ];
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("selectList");
    },
  },
  methods: {
    setJobTitleList: function (value) {
      if (value) {
        this.getJobTitleList(value.Class);
      }
      else {
        this.jobTitleList = [];
      }
    },
    getOfficeData: function () {
      let officeName = this.getData("officeLocation");
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.ResName === officeName);
      return building ? building : null;
    },
    getJobTitle: function () {
      let lg = this.$i18n.locale;
      let jobTitle = this.getData("jobTitle");
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleMap = jobTitleList.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
      let jobTitleObj = jobTitleMap.find((b) => b.Fr === jobTitle);
      if (!jobTitleObj) {
        jobTitleObj = jobTitleMap.find((b) => b.En === jobTitle);
      }
      
      return jobTitleObj ? jobTitleObj : null;
    },
    getData: function (type) {
      let user = this.$store.getters.getUser;
      let propertyValue = user[type];
      if (type === "businessPhones" && Array.isArray(propertyValue)) {
        propertyValue = propertyValue.length > 0 ? propertyValue[0] : "";
      }
      return propertyValue;
    },
    setUserDirectReports: function () {
      let user = this.$store.getters.getUser;
      return this.filterDirectReports(user.directReports);
    },
    filterDirectReports: function (directReports) {
      //*
      return directReports.filter((dr) => dr.displayName.indexOf("*") === -1);
    },
    getPhoneNumber: function (type) {
      let propertyValue = this.getData(type);
      let formatPhone = null;

      if (type === "businessPhones" && Array.isArray(propertyValue)) {
        propertyValue = propertyValue.length > 0 ? propertyValue[0] : "";
      }

      if (propertyValue) {
        let splitPhone = propertyValue.split("x"); //get the string before and after the extension
        if (splitPhone.length === 2) {
          formatPhone = splitPhone[0].replace(/\D/g, "");
        } else {
          formatPhone = propertyValue.replace(/\D/g, "");
        }

        formatPhone = formatPhone.slice(1); //remove 1 in front of the phone number
      }

      return formatPhone;
    },
    getExt: function () {
      let ext = null;
      let propertyValue = this.getData("businessPhones");

      if (propertyValue) {
        let splitPhone = propertyValue.split("x");

        if (splitPhone.length === 2) {
          ext = splitPhone[1];
        }
      }

      return ext;
    },
    searchJobTitleList(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let JobTitlePar = o.Fr ? o.Fr.replace(/[()]/g, "") : ""; //Remove ()
        let JobTitle = JobTitlePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        return (
          JobTitle.toLowerCase().includes(s) 
        );
      });

      return find;
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchUser: function (searchText) {
      this.actionCall("allUsers", searchText);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;
      let promise = this.validateToken();
      promise.then((value) => {
        if (value) {
          if (type === "allUsers") {
            this.searchUsersBySearchText(value, searchText);
          }
          if (type === "selectList") {
            this.getBuildingList();
            let office = this.form.office;
            this.setJobTitleList(office);
          }
        }
      });
    },
    getUserDirectReports(token, idUser) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}?$expand=directReports`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          this.spinner = false;
          if (response.status === 200) {
            if (Array.isArray(response.data.directReports)) {
              this.directReports = this.filterDirectReports(
                response.data.directReports
              );
            }
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getAllUsers(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$orderby=displayName&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) => a.displayName.indexOf("*") === -1 && this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    //get the manager's chain up to the root node
    getManagerChains(token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me?$expand=manager($levels=max;$select=id,displayName,jobTitle)&$select=id,displayName&$count=true";
      return axios.get(graphEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ConsistencyLevel: "eventual",
        },
      });
    },
    getManager(token) {
      let graphEndpoint = "https://graph.microsoft.com/v1.0/me/manager";
      return axios.get(graphEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ConsistencyLevel: "eventual",
        },
      });
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapSelectList(buildings, "building");
    },
    getJobTitleList: function (classId) {
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleFilter = jobTitleList.filter(j => j.Class === classId);
      this.mapSelectList(jobTitleFilter, "jobTitle");
    },
    getManagerRoot: function (obj) {
      for (let property in obj) {
        if (property === "manager") {
          this.managers.unshift(obj.manager);
          if (obj.manager.manager) {
            this.getManagerRoot(obj.manager);
          }
        }
      }
    },
    mapSelectList: function (list, type) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < list.length; i++) {
        let find = uniqueClass.find((c) => c === list[i].Class);
        if (!find) {
          uniqueClass.push(list[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let ListByClassWithLabel = [];

      if (type === 'building') {
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = list.filter((b) => b.Class === uniqueClass[i]);
          let className =
            lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ ResName: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }
          this.buildings = ListByClassWithLabel.flat();
      }

      if (type === 'jobTitle') {
        let jobTitleMap = list.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = jobTitleMap.filter((b) => b.Class === uniqueClass[i]);
          let className = lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          //let displayName = lg === "fr" ? blist[0].Fr : blist[0].En;
          blist.unshift({ jobTitle: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }

        this.jobTitleList = ListByClassWithLabel.flat();
      }
    },
    displayManagerName: function () {
      let manager = this.form.manager;
      let name = "";
      if (manager) {
        name = manager.displayName ? manager.displayName : "";
      }
      return name;
    },
    getPhoneFormat: function (type) {
      let formatPhone = " "; //format for graph if we want to set an empty value
      let phone = this.form[type];
      if (phone) {
        let part0 = "+1";
        let part1 = phone.slice(0, 3);
        let part2 = phone.slice(3, 6);
        let part3 = phone.slice(6);

        formatPhone = part0.concat(" ", part1, "-", part2, "-", part3);

        if (type === "businessPhones" && this.form.ext) {
          formatPhone = formatPhone.concat("x", this.form.ext);
        }
      }

      return formatPhone;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.success = false;
        this.error = "";
        this.info = "";
        let promise = this.validateToken();
        promise.then((userToken) => {
          let request = [];
          let appToken = this.$store.getters.getAppAccessToken;
          let user = this.$store.getters.getUser;
          let userData = this.validateUserDataChange();
          let phoneData = this.validatePhonesDataChange();

          if (Object.keys(userData).length > 0) {
            request.push(this.updateUser(appToken, userData, user));
          }

          if (this.bypassManager === "needValidation") {
            if (this.form.manager.id !== this.form.IdManager) {
              request.push(this.updateManager(appToken, user));
            }
          }

          if (Object.keys(phoneData).length > 0) {
            request.push(this.updatePhone(userToken, phoneData));
          }

          if (request.length > 0) {
            this.spinner = true;
            Promise.allSettled(request).then((results) => {
              this.spinner = false;
              if (results.length > 0) {
                //log info
                let obj = {
                  Email: this.user.mail,
                  LogOut: null,
                  Request: null,
                  Submit: JSON.stringify(results),
                };
                this.updateSession(obj);
                for (let i = 0; i < results.length; i++) {
                  if (results[i].status !== "fulfilled") {
                    this.error = this.$t("info.errorgeneric");
                  } else {
                    this.success = true;
                    let res = results[i].value;
                    if (res.status !== 204) {
                      this.success = false;
                      this.error = this.$t("info.errorgeneric");
                    }
                  }
                }
              } else {
                this.error = this.$t("info.errorgeneric");
              }
            });
          }
        });
      }
    },
    updateUser(token, obj, user) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}`;
      return axios.patch(graphEndpoint, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    },
    updateManager(token, user) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}/manager/$ref`;
      return axios.put(
        graphEndpoint,
        {
          "@odata.id": `https://graph.microsoft.com/v1.0/users/${this.form.manager.id}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    },
    updatePhone(token, obj) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/me`;
      return axios.patch(graphEndpoint, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    },
    validateUserDataChange() {
      let user = {};

      if (this.validateDataChange("givenName", this.form.fname)) {
        user.givenName = this.form.fname;
      }

      if (this.validateDataChange("surname", this.form.lname)) {
        user.surname = this.form.lname;
      }

      if (this.validateDataChange("jobTitle", this.form.job)) {
        let lg = this.$i18n.locale; 
        user.jobTitle = lg === 'fr' ? this.form.job.Fr : this.form.job.En;
      }

      if (this.validateDataChange("preferredLanguage", this.form.communication)) {
        user.preferredLanguage = this.form.communication;
      }

      if (
        this.validateDataChange("officeLocation", this.form.office) &&
        !this.bypassOffice
      ) {
        user.officeLocation = this.form.office.ResName;
        user.country = this.form.office.Country;
        user.usageLocation = this.form.office.CountryCode;
        user.state = this.form.office.State;
        user.city = this.form.office.City;
        user.streetAddress = this.form.office.Street;
        user.postalCode = this.form.office.Zip;
      }

      return user;
    },
    validatePhonesDataChange() {
      let user = {};
      let phoneFormat = this.getPhoneFormat("businessPhones");
      let cellFormat = this.getPhoneFormat("tel");

      if (this.validateDataChange("businessPhones", phoneFormat)) {
        user.businessPhones = [phoneFormat];
      }

      if (this.validateDataChange("mobilePhone", cellFormat)) {
        user.mobilePhone = cellFormat;
      }

      return user;
    },
    validateDataChange(type, data) {
      let user = this.$store.getters.getUser;
      let propertyValue = user[type];

      if (type === "businessPhones" && Array.isArray(propertyValue)) {
        propertyValue = propertyValue[0];
      }

      return propertyValue !== data ? true : false;
    },
    openmodal: function (id, sub) {
      this.userToUpdate = sub;
      this.$bvModal.show(id);
    },
  },
  components: {
    SendNotificationEmail,
    DeleteUserConfirmation,
    AddUserToManager,
  },
};
</script>
