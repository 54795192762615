<template>
  <b-container>
    <b-row class="pt-2">
      <b-col sm="6" class="mt-2">
        <div>
          <strong>Microsoft Tenant</strong>
        </div>
        <b-form-select v-model="tenant" :options="tenantList" v-on:change="OnTenantClick" class="mt-2"></b-form-select>
      </b-col>
    </b-row>
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input v-model="search" :placeholder="$t('action.search')"></b-form-input>
          <div>
            <small>&#9432; {{ $t("info.searchDirectory") }}</small>
          </div>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
        <b-col sm="6" class="mt-2">
          <ExcelReport :data="getAutotableBody()" fileName="MyProfile.xlsx" worksheet="UserDirectory" :range="getRange()"
            :sessionLog="sessionLog" />
        </b-col>
      </b-row>

      <b-row v-if="getAppRole(user.AppRole, 1)" class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-checkbox id="buildingFilter" v-model="buildingFilter" name="buildingFilter" value="yes"
            unchecked-value="no">
            {{ $t("label.buildingFilter") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row v-if="getAppRole(user.AppRole, 1)">
        <b-col sm="6" class="mt-2">
          <b-form-checkbox id="jobTitleFilter" v-model="jobTitleFilter" name="jobTitleFilter" value="yes"
            unchecked-value="no">
            {{ $t("label.jobTitleFilter") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table id="UserDirectoryTable" stacked="lg" bordered :fields="myfields" :items="items" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(displayName)="row">
              <b-button variant="link" size="sm" @click="openmodal('userInfo', row.item)">{{ row.item.displayName
              }}</b-button>
            </template>
            <template v-slot:cell(officeLocation)="row">
              <b-button variant="link" size="sm" @click="openmodal('officeLocation', row.item)">{{ row.item.officeLocation
              }}</b-button>
            </template>
            <template v-if="sessionsList.length > 0 && getAppRole(user.AppRole, 1)" v-slot:cell(countUserSessions)="row">
              <b-button v-if="parseInt(row.item.countUserSessions) > 0" class="btn-block" size="sm" variant="link"
                @click="openmodal('request', row.item)">{{ row.item.countUserSessions }}</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="UserDirectoryTable"></b-pagination>
        </b-col>
      </b-row>

      <b-modal id="userInfo" size="xl" :title="$t('label.contactDetails')" hide-footer>
        <UserInfoModal :userInfo="dataFromTable" :currentUser="user" :tenant="tenant" />
      </b-modal>
      <b-modal id="request" :title="$t('label.lastRequest')" hide-footer>
        <SessionConnexionModal :sessionsList="dataFromTable" />
      </b-modal>
      <b-modal id="officeLocation" :title="$t('label.offices')" hide-footer>
        <BuildingAddressModal :userInfo="dataFromTable" />
      </b-modal>
    </div>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner style="width: 3rem; height: 3rem" class="spinner" variant="success" label="Spinning"
        disabled="labelStatus"></b-spinner>
    </div>
  </b-container>
</template>

<script>
import ExcelReport from "./Reports/ExcelReport.vue";
import SessionConnexionModal from "./Modal/SessionConnexionModal.vue";
import UserInfoModal from "./Modal/UserInfoModal.vue";
import BuildingAddressModal from "./Modal/BuildingAddressModal.vue";
import axios from "axios";
import moment from "moment";
import { globalF } from "./Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      tenant: this.getCurrentTenant(),
      rawData: [],
      tableData: [],
      count: null,
      dataFromTable: {},
      sortBy: "displayName",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      search: "",
      buildingFilter: "no",
      jobTitleFilter: "no",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
    };
  },
  mounted() {
    let promise = this.validateToken();
    promise.then((value) => {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=accountEnabled eq true and userType eq 'Member' and 
      assignedPlans/any(a:a/servicePlanId eq 9aaf7827-d63c-4b61-89c3-182f06f82e5c and a/capabilityStatus eq 'Enabled') or 
      accountEnabled eq true and userType eq 'Member' and
      assignedPlans/any(a:a/servicePlanId eq efb87545-963c-4e0d-99df-69c6916d9eb0 and a/capabilityStatus eq 'Enabled') or
      accountEnabled eq true and userType eq 'Member' and
      assignedPlans/any(a:a/servicePlanId eq 4a82b400-a79f-41a4-b4e2-e94f5787b113 and a/capabilityStatus eq 'Enabled')&$count=true`;
      this.getAllUsers(value, graphEndpoint);
    });
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    sessionLog: function () {
      return {
        request: "Generate Excel Report",
        length: this.items.length,
        search: this.search,
      };
    },
    sessionsList: function () {
      return this.$store.getters.getAllSessions;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let returnList = []

      let find = mydatalist.filter((o) => {
        let dp = o.displayName ? o.displayName : "";
        let displayName = dp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let gn = o.givenName ? o.givenName : "";
        let givenName = gn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let sn = o.surname ? o.surname : "";
        let surname = sn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let mail = o.mail ? o.mail : "";

        let jt = o.jobTitle ? o.jobTitle : "";
        let jobTitle = jt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let off = o.officeLocation ? o.officeLocation.replace(/[()]/g, "") : ""; //Remove ()
        let officeLocation = off
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return (
          displayName.toLowerCase().includes(s) ||
          givenName.toLowerCase().includes(s) ||
          surname.toLowerCase().includes(s) ||
          mail.toLowerCase().includes(s) ||
          jobTitle.toLowerCase().includes(s) ||
          officeLocation.toLowerCase().includes(s)
        );
      });

      if (this.buildingFilter === "yes") {
        returnList = find.filter((f) => {
          let office = f.officeLocation;
          let buildings = this.$store.getters.getBuildings;
          let exist = buildings.find(b => b.ResName === office);
          if (!exist) {
            return f;
          }
        });
      }
      else if (this.jobTitleFilter === "yes") {
        returnList = find.filter((f) => {
          let jobTitle = f.jobTitle;
          let jobTitleList = this.$store.getters.getJobTitleList;
          let exist = jobTitleList.find(b => b.Fr === jobTitle || b.En === jobTitle);
          if (!exist) {
            return f;
          }
        });
      }
      else {
        returnList = find;
      }

      return returnList;
    },
    myfields: function () {
      //let sessions = this.$store.getters.getAllSessions;

      let myFields = [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "givenName", sortable: true, label: this.$t("label.fname") },
        { key: "surname", sortable: true, label: this.$t("label.lname") },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        { key: "jobTitle", sortable: true, label: this.$t("label.job") },
        {
          key: "officeLocation",
          sortable: true,
          label: this.$t("label.offices"),
        },
      ];

      if (this.sessionsList.length > 0 && this.getAppRole(this.user.AppRole, 1)) {
        myFields.push({
          key: "countUserSessions",
          sortable: true,
          label: this.$t("label.connexionCount"),
        });
      }

      return myFields;
    },
    tenantList: function () {
      let tenantList = this.$store.getters.getTenantList;
      return tenantList.map(t => {
        return {
          "value" : t.value,
          "text" : t.name
        }
      });
    }
  },
  watch: {
    currentLg: function () { },
    buildingFilter: function () {
      if (this.buildingFilter === 'yes') {
        this.jobTitleFilter = 'no';
      }
    },
    jobTitleFilter: function () {
      if (this.jobTitleFilter === 'yes') {
        this.buildingFilter = 'no';
      }
    }
  },
  methods: {
    OnTenantClick() {
      if (this.tenant) {
        this.spinner = true;
        this.error = "";
        this.rawData = [];
        this.tableData = [];

        let tenantList = this.$store.getters.getTenantList;
        let tenantId = tenantList.find(t => t.value === this.tenant).id;
        let obj = {
          request: "getAllGraphUser",
          tenantId: tenantId,
        };
        axios
          .post(this.url, obj, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            this.spinner = false;
            //this.rawData = response.data;
            this.formatRawData(response.data);
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getCurrentTenant() {
      let currentAccount = this.$store.getters.getMicrosoftAccount;
      let tenantList = this.$store.getters.getTenantList;
      return tenantList.find(t => t.id === currentAccount.tenantId).value;
    },
    getAllUsers(token, graphEndpoint) {
      this.spinner = true;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data["@odata.count"]) {
              this.count = response.data["@odata.count"];
            }
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getAllUsers(token, nextLink);
            } else {
              this.spinner = false;
              let flatTable = this.rawData.flat();
              this.formatRawData(flatTable);
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    formatRawData: function (flatTable) {

      let sessions = this.sessionsList; //this.$store.getters.getAllSessions;

      if (sessions.length > 0 && this.getAppRole(this.user.AppRole, 1)) {
        this.tableData = flatTable.map((dl) => {
          let userSessions = sessions.filter((s) => s.Email === dl.mail);
          dl.logIn = null;
          if (userSessions.length > 0) {
            userSessions.sort((a, b) => {
              return moment(a.LogIn).toDate() + moment(b.LogIn).toDate();
            });
            dl.logIn = userSessions[0].LogIn;
          }
          dl.userSessions = userSessions;
          dl.countUserSessions = userSessions.length;

          return dl;
        });
      } else {
        this.tableData = flatTable;
      }
    },
    resetTable: function () {
      this.search = "";
    },
    openmodal: function (id, value) {
      this.dataFromTable = value;
      this.$bvModal.show(id);
    },
    getAutotableBody: function () {
      let all = [];
      let data = this.items.map((i) => {
        return i;
      });

      let header = [
        "Display name",
        "Given name",
        "Surname",
        "Mail",
        "Job title",
        "Mobile phone",
        "Business phones",
        "Office location",
      ];

      all.push(header);
      all.push(["", "", "", "", "", "", "", ""]);


      for (let i = 0; i < data.length; i++) {
        let dataArray = [];
        let businessPhonesList = "";
        let businessPhones = data[i].businessPhones;
        if (businessPhones) {
          businessPhonesList = businessPhones.length > 0 ? businessPhones.toString() : "";
        }

        dataArray.push(
          data[i].displayName,
          data[i].givenName,
          data[i].surname,
          data[i].mail,
          data[i].jobTitle,
          data[i].mobilePhone,
          businessPhonesList,
          data[i].officeLocation
        );

        all.push(dataArray);
      }

      return all;
    },
    getRange: function () {
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      let excelList = this.getAutotableBody();
      return "A1:H" + excelList.length;
    },
  },
  components: {
    ExcelReport,
    SessionConnexionModal,
    UserInfoModal,
    BuildingAddressModal
  },
};
</script>
